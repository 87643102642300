import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const CityWiseReports = () => {
  const [cityWiseData, setCityWiseData] = useState({});

  useEffect(() => {
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/summary/citywise"
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setCityWiseData(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  var totalObject = {
    countIndore: 0,
    countNotIndore: 0,
    countReportedIndore: 0,
    countReportedNotIndore: 0,
  };

  //   cityWiseData = {
  //     "Male": [
  //       {
  //         "zoneName": "Inner Vehicle",
  //         "countIndore": 62,
  //         "countNotIndore": 81,
  //         "countReportedIndore": 10,
  //         "countReportedNotIndore": 8
  //       },
  //       {
  //         "zoneName": "Inner Sangat",
  //         "countIndore": 60,
  //         "countNotIndore": 179,
  //         "countReportedIndore": 12,
  //         "countReportedNotIndore": 8
  //       },
  //       {
  //         "zoneName": "Bus Stand 1",
  //         "countIndore": 81,
  //         "countNotIndore": 104,
  //         "countReportedIndore": 9,
  //         "countReportedNotIndore": 2
  //       },
  //       {
  //         "zoneName": "Bus Stand 2",
  //         "countIndore": 19,
  //         "countNotIndore": 39,
  //         "countReportedIndore": 1,
  //         "countReportedNotIndore": 8
  //       },
  //       {
  //         "zoneName": "Parking 1",
  //         "countIndore": 50,
  //         "countNotIndore": 53,
  //         "countReportedIndore": 8,
  //         "countReportedNotIndore": 5
  //       },
  //       {
  //         "zoneName": "Parking 2",
  //         "countIndore": 45,
  //         "countNotIndore": 56,
  //         "countReportedIndore": 2,
  //         "countReportedNotIndore": 7
  //       },
  //       {
  //         "zoneName": "Route 1",
  //         "countIndore": 69,
  //         "countNotIndore": 157,
  //         "countReportedIndore": 20,
  //         "countReportedNotIndore": 6
  //       },
  //       {
  //         "zoneName": "Route 2",
  //         "countIndore": 60,
  //         "countNotIndore": 78,
  //         "countReportedIndore": 11,
  //         "countReportedNotIndore": 9
  //       },
  //       {
  //         "zoneName": "PPC",
  //         "countIndore": 15,
  //         "countNotIndore": 33,
  //         "countReportedIndore": 4,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "Office",
  //         "countIndore": 26,
  //         "countNotIndore": 5,
  //         "countReportedIndore": 5,
  //         "countReportedNotIndore": 1
  //       },
  //       {
  //         "zoneName": "HOD",
  //         "countIndore": 2,
  //         "countNotIndore": 0,
  //         "countReportedIndore": 0,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "CR",
  //         "countIndore": 9,
  //         "countNotIndore": 0,
  //         "countReportedIndore": 1,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "TRAFIC(CONTROL ROOM)",
  //         "countIndore": 191,
  //         "countNotIndore": 57,
  //         "countReportedIndore": 2,
  //         "countReportedNotIndore": 1
  //       }
  //     ],
  //     "Female": [
  //       {
  //         "zoneName": "PPC",
  //         "countIndore": 25,
  //         "countNotIndore": 20,
  //         "countReportedIndore": 8,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "Office",
  //         "countIndore": 15,
  //         "countNotIndore": 0,
  //         "countReportedIndore": 9,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "Group A (Bus Stand Area) ",
  //         "countIndore": 33,
  //         "countNotIndore": 29,
  //         "countReportedIndore": 10,
  //         "countReportedNotIndore": 1
  //       },
  //       {
  //         "zoneName": "Group B (Zone 2 Area)",
  //         "countIndore": 36,
  //         "countNotIndore": 34,
  //         "countReportedIndore": 12,
  //         "countReportedNotIndore": 1
  //       },
  //       {
  //         "zoneName": "Group C (Zone 3 Area)",
  //         "countIndore": 24,
  //         "countNotIndore": 31,
  //         "countReportedIndore": 8,
  //         "countReportedNotIndore": 3
  //       },
  //       {
  //         "zoneName": "Group E (Langar Area)",
  //         "countIndore": 27,
  //         "countNotIndore": 42,
  //         "countReportedIndore": 8,
  //         "countReportedNotIndore": 4
  //       },
  //       {
  //         "zoneName": "Group D (Canteen Area)",
  //         "countIndore": 42,
  //         "countNotIndore": 31,
  //         "countReportedIndore": 11,
  //         "countReportedNotIndore": 2
  //       },
  //       {
  //         "zoneName": "HOD",
  //         "countIndore": 2,
  //         "countNotIndore": 0,
  //         "countReportedIndore": 2,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "CR",
  //         "countIndore": 4,
  //         "countNotIndore": 0,
  //         "countReportedIndore": 3,
  //         "countReportedNotIndore": 0
  //       },
  //       {
  //         "zoneName": "TRAFIC(CONTROL ROOM)",
  //         "countIndore": 68,
  //         "countNotIndore": 11,
  //         "countReportedIndore": 0,
  //         "countReportedNotIndore": 2
  //       }

  return (
    <>
      <div className="text-3xl uppercase col-span-12 mt-5">
        Indore and Outstation Report
      </div>
      <div className="w-full text-right">
        <ReactHTMLTableToExcel
          filename={"Indore and Outstation Report"}
          sheet={"Indore and Outstation Report"}
          table="table-1"
          buttonText="  Excel"
          className="fas fa-download text-green-600"
        >
          <button>
            <img
              src="/images/icons/excel.png"
              alt="Excel"
              className="h-5 w-5 cursor-pointer"
            />{" "}
          </button>
        </ReactHTMLTableToExcel>
      </div>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12">
          <table className="table-auto" id="table-1">
            <thead>
              <tr>
                <th className="border border-black text">Zone Name</th>
                <th className="border border-black text">Indore</th>
                <th className="border border-black text">Reported Indore</th>
                <th className="border border-black text">%age</th>
                <th className="border border-black text">OutStation</th>
                <th className="border border-black text">
                  Reported OutStation
                </th>
                <th className="border border-black text">%age</th>
                <th className="border border-black text">Total Alloted</th>
                <th className="border border-black text">Total Reported</th>
                <th className="border border-black text">%age</th>
              </tr>
            </thead>
            <tbody>
              {cityWiseData &&
                Object.keys(cityWiseData).length > 0 &&
                Object.keys(cityWiseData).map((key) => {
                  var genderObject = {
                    countIndore: 0,
                    countNotIndore: 0,
                    countReportedIndore: 0,
                    countReportedNotIndore: 0,
                  };

                  return (
                    <>
                      <tr>
                        <th colSpan="7">{key}</th>
                      </tr>
                      {cityWiseData[key].map((data) => {
                        genderObject["countIndore"] += data.countIndore;
                        genderObject["countNotIndore"] += data.countNotIndore;
                        genderObject["countReportedIndore"] +=
                          data.countReportedIndore;
                        genderObject["countReportedNotIndore"] +=
                          data.countReportedNotIndore;

                        totalObject["countIndore"] += data.countIndore;
                        totalObject["countNotIndore"] += data.countNotIndore;
                        totalObject["countReportedIndore"] +=
                          data.countReportedIndore;
                        totalObject["countReportedNotIndore"] +=
                          data.countReportedNotIndore;

                        return (
                          <tr>
                            <td className="border border-black">
                              {data.zoneName}
                            </td>
                            <td className="border border-black">
                              {data.countIndore}
                            </td>
                            <td className="border border-black">
                              {data.countReportedIndore}
                            </td>
                            <td className="border border-black">
                              {Math.round(
                                (data.countReportedIndore / data.countIndore ||
                                  0) * 100
                              ) + "%"}
                            </td>
                            <td className="border border-black">
                              {data.countNotIndore}
                            </td>

                            <td className="border border-black">
                              {data.countReportedNotIndore}
                            </td>
                            <td className="border border-black">
                              {Math.round(
                                (data.countReportedNotIndore /
                                  data.countNotIndore || 0) * 100
                              ) + "%"}
                            </td>
                            <td className="border border-black">
                              {data.countIndore + data.countNotIndore}
                            </td>
                            <td className="border border-black">
                              {data.countReportedIndore +
                                data.countReportedNotIndore}
                            </td>
                            <td className="border border-black">
                              {Math.round(
                                ((data.countReportedIndore +
                                  data.countReportedNotIndore) /
                                  (data.countIndore + data.countNotIndore) ||
                                  0) * 100
                              ) + "%"}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="border border-black text">Total</td>
                        <td className="border border-black">
                          {genderObject["countIndore"]}
                        </td>
                        <td className="border border-black">
                          {genderObject["countReportedIndore"]}
                        </td>
                        <td className="border border-black">
                          {Math.round(
                            (genderObject["countReportedIndore"] /
                              genderObject["countIndore"] || 0) * 100
                          ) + "%"}
                        </td>
                        <td className="border border-black">
                          {genderObject["countNotIndore"]}
                        </td>
                        <td className="border border-black">
                          {genderObject["countReportedNotIndore"]}
                        </td>
                        <td className="border border-black">
                          {Math.round(
                            (genderObject["countReportedNotIndore"] /
                              genderObject["countNotIndore"] || 0) * 100
                          ) + "%"}
                        </td>
                        <td className="border border-black">
                          {genderObject["countIndore"] +
                            genderObject["countNotIndore"]}
                        </td>
                        <td className="border border-black">
                          {genderObject["countReportedIndore"] +
                            genderObject["countReportedNotIndore"]}
                        </td>
                        <td className="border border-black">
                          {Math.round(
                            ((genderObject["countReportedIndore"] +
                              genderObject["countReportedNotIndore"]) /
                              (genderObject["countIndore"] +
                                genderObject["countNotIndore"]) || 0) * 100
                          ) + "%"}
                        </td>
                      </tr>
                    </>
                  );
                })}
              <tr>
                <td className="border border-black text">Total</td>
                <td className="border border-black">
                  {totalObject["countIndore"]}
                </td>
                <td className="border border-black">
                  {totalObject["countReportedIndore"]}
                </td>
                <td className="border border-black">
                  {Math.round(
                    (totalObject["countReportedIndore"] /
                      totalObject["countIndore"] || 0) * 100
                  ) + "%"}
                </td>
                <td className="border border-black">
                  {totalObject["countNotIndore"]}
                </td>
                <td className="border border-black">
                  {totalObject["countReportedNotIndore"]}
                </td>
                <td className="border border-black">
                  {Math.round(
                    (totalObject["countReportedNotIndore"] /
                      totalObject["countNotIndore"] || 0) * 100
                  ) + "%"}
                </td>
                <td className="border border-black">
                  {totalObject["countIndore"] + totalObject["countNotIndore"]}
                </td>
                <td className="border border-black">
                  {totalObject["countReportedIndore"] +
                    totalObject["countReportedNotIndore"]}
                </td>
                <td className="border border-black">
                  {Math.round(
                    ((totalObject["countReportedIndore"] +
                      totalObject["countReportedNotIndore"]) /
                      (totalObject["countIndore"] +
                        totalObject["countNotIndore"]) || 0) * 100
                  ) + "%"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default CityWiseReports;
