import { useState, useEffect } from "react";

function ZoneCard(props) {
  const genderIconMapping = {
    M: { icon: "/images/icons/male.png", bgcolor: "bg-blue-600" },
    F: { icon: "/images/icons/female.png", bgcolor: "bg-green-600" },
    C: { icon: "/images/icons/combined.png", bgcolor: "bg-yellow-600" },
  };

  return (
    <>
      <div
        className={
          "col-span-12 md:col-span-6 2xl:col-span-3 rounded text-white " +
          genderIconMapping[props.zone.gender].bgcolor
        }
      >
        <div className="grid grid-cols-12 gap-5  items-center">
          <div className="col-span-4 text-2xl text-center p-5 ">
            <img
              src={genderIconMapping[props.zone.gender].icon}
              alt={props.zone.gender}
              className="w-25 h-25 mx-auto"
            />
          </div>

          <div className="col-span-8 text-2xl text-left p-5">
            <div className="text-xl uppercase">{props.zone.name}</div>
            <div className="text-6xl">{props.zone.reported}</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ZoneCard;
