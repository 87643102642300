var ADMIN_MENU_ITEMS = {
  // Admin: [
  //   {
  //     name: "Add Sewadar",
  //     path: "/admin/add-sewadar",
  //     icon: "fas fa-user-plus",
  //     component: "AddSewadar",
  //   },
  //   {
  //     name: "Edit Sewadar",
  //     path: "/admin/edit-sewadar",
  //     icon: "fas fa-user-edit",
  //     component: "EditSewadar",
  //   },
  //   {
  //     name: "Add User",
  //     path: "/admin/add-user",
  //     icon: "fas fa-user-plus",
  //     component: "AddUser",
  //   },
  //   {
  //     name: "Manage Users",
  //     path: "/admin/manage-users",
  //     icon: "fas fa-user-edit",
  //     component: "ManageUsers",
  //   },
  //   {
  //     name: "Delete Reporting",
  //     path: "/admin/delete-reporting",
  //     icon: "fas fa-user-edit",
  //     component: "DeleteReporting",
  //   },
  // ],
};

var MENU_ITEMS = {
  Attendance: [
    {
      name: "Report Sewadar",
      path: "/admin/report-sewadars",
      icon: "fas fa-user",
      component: "ReportSewadar",
    },
  ],
  Leave: [
    {
      name: "Mark Leave",
      path: "/admin/weekly-mark-leave",
      icon: "fas fa-building",
      component: "SewadarLeave",
      status: "NOT RECEIVED",
    },
  ],

  Reports: [
    {
      name: "Statistics",
      path: "/admin/statistics",
      icon: "fas fa-building",
      component: "WeeklyStatistics",
    },

    {
      name: "Incharge Details",
      path: "/admin/weekly-incharge-details",
      icon: "fas fa-building",
      component: "InchargeDetails",
    },
    {
      name: "Attendance Report Gents",
      path: "/admin/weekly-attendance-gents",
      icon: "fas fa-building",
      component: "WeeklyAttendance",
    },
    {
      name: "Attendance Report Ladies",
      path: "/admin/weekly-attendance-ladies",
      icon: "fas fa-building",
      component: "WeeklyAttendance",
    },
    {
      name: "Training Sewadars",
      path: "/admin/weekly-attendance-gents-training",
      icon: "fas fa-building",
      component: "WeeklyAttendance",
    },
    {
      name: "Absent List Gents",
      path: "/admin/weekly-absent-gents",
      icon: "fas fa-building",
      component: "AbsentList",
    },
    {
      name: "Absent List Ladies",
      path: "/admin/weekly-absent-ladies",
      icon: "fas fa-building",
      component: "AbsentList",
    },
    {
      name: "Leave List Gents",
      path: "/admin/weekly-leave-gents",
      icon: "fas fa-building",
      component: "WeeklyAttendance",
    },
    {
      name: "Leave List Ladies",
      path: "/admin/weekly-leave-ladies",
      icon: "fas fa-building",
      component: "WeeklyAttendance",
    },
    {
      name: "Point Report List",
      path: "/admin/pointreport",
      icon: "fas fa-building",
      component: "PointReport",
    },
  ],
  "Daily Attendance": [
    {
      name: "Mark Daily Attendance",
      path: "/admin/mark-daily-attendance",
      icon: "fas fa-building",
      component: "MarkDailyAttendance",
    },
    {
      name: "Daily Attendance Report",
      path: "/admin/daily-attendance-report",
      icon: "fas fa-building",
      component: "DailyAttendanceReport",
    },
  ],
};

var ZONE_OFFICE_MENU = {
  Reporting: [
    {
      name: "Reported Sewadars",
      path: "/admin/pointreport",
      icon: "fas fa-user",
      component: "PointReport",
    },
  ],
};

export { MENU_ITEMS, ADMIN_MENU_ITEMS, ZONE_OFFICE_MENU };
