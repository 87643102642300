import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function SewasamitiReport(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("NOT RECEIVED");
  const [header, setHeader] = useState([]);
  const tableRef = React.createRef();
  const [isExportReady, setIsExportReady] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("reportCity")) {
      setCity(localStorage.getItem("reportCity"));
    } else {
      // Redirect to login page
      window.location.href = "/";
    }
  }, []);
  useEffect(() => {
    console.log("Scrolling to table");
    if (tableRef.current) {
      console.log({ tableRef });
      setIsExportReady(true);
      //tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableRef]);
  useEffect(() => {
    if (props.status !== status) {
      setStatus(props.status);
    }
  }, [props.status]);

  useEffect(() => {
    if (city === "" || status === "") {
      return;
    }

    setLoading(true);
    var payload = {
      city: city,
      status: status,
    };
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/sewasamiti/report",
        payload
      )
      .then((response) => {
        if (response.data.status === "SUCCESS") {
          setData(response.data.data);
        } else {
          alert(response.data.message);
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [city, status]);

  useEffect(() => {
    if (data.length > 0) {
      setHeader(Object.keys(data[0]));
    }
  }, [data]);

  const headingMap = {
    "NOT RECEIVED": "Badges Not Received",
    RECEIVED: "Badges Received",
    RETURNED: "Badges Returned",
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">
          {headingMap[status]}
        </div>
        {loading && <div className="col-span-12">Loading...</div>}
        {!loading && data && data.length > 0 && header && header.length > 0 && (
          <>
            <div className="col-span-12">Total Records: {data.length}</div>
            <div className="col-span-12 text-right">
              {isExportReady && (
                <ReactHTMLTableToExcel
                  filename={headingMap[status]}
                  sheet={headingMap[status]}
                  table="table1"
                  buttonText="  Excel"
                  className="fas fa-download text-green-600"
                >
                  <button>
                    <img
                      src="/images/icons/excel.png"
                      alt="Excel"
                      className="h-5 w-5 cursor-pointer"
                    />{" "}
                  </button>
                </ReactHTMLTableToExcel>
              )}
            </div>
            <table
              className="table-auto col-span-12"
              id="table1"
              ref={tableRef}
            >
              <thead>
                <tr>
                  {header.map((item) => {
                    return (
                      <th className="border px-4 py-2" key={item}>
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      {header.map((key, index) => {
                        return (
                          <td className="border text px-4 py-2" key={index}>
                            {item[key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}
export default SewasamitiReport;
