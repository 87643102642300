import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { PROGRAM_YEAR } from "Configuration/config";
import { MENU_ITEMS, ADMIN_MENU_ITEMS, ZONE_OFFICE_MENU } from "Configuration";

function Sidebar(props) {
  const [user, setUser] = useState(null);
  const [collapseShow, setCollapseShow] = useState("hidden");
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userdata")));
    console.log(user);
  }, []);
  useEffect(() => {
    if (user) {
      console.log({ user });
    }
  }, [user]);

  return (
    <>
      <nav className="navigation md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-black mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            Weekly Satsang
            <br /> {PROGRAM_YEAR}
          </Link>
          {/* User */}

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Weekly Satsang
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <>
              {user &&
                user.role === "ZONE_OFFICE" &&
                Object.keys(ZONE_OFFICE_MENU).map((key) => {
                  return (
                    <div key={key}>
                      <hr className="my-4 md:min-w-full" />
                      <h6 className="md:min-w-full text-left text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                        {key}
                      </h6>
                      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                        {ZONE_OFFICE_MENU[key].map((item) => {
                          return (
                            <li className="inline-flex" key={item.name}>
                              <NavLink
                                className={(isActive) =>
                                  "text-xs uppercase py-3 font-bold block " +
                                  (isActive
                                    ? "text-blue-500 hover:text-blue-600"
                                    : "text-gray-700 hover:text-gray-500")
                                }
                                to={item.path}
                                onClick={() => setCollapseShow("hidden")}
                              >
                                <i className={item.icon + " mr-2 text-sm "}></i>{" "}
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              {user &&
                ["HOD", "CR", "OFFICE", "ADMIN"].includes(user.role) &&
                Object.keys(MENU_ITEMS).map((key) => {
                  return (
                    <div key={key}>
                      <hr className="my-4 md:min-w-full" />
                      <h6 className="md:min-w-full text-left text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                        {key}
                      </h6>
                      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                        {MENU_ITEMS[key].map((item) => {
                          return (
                            <li className="inline-flex" key={item.name}>
                              <NavLink
                                className={(isActive) =>
                                  "text-xs uppercase py-3 font-bold block " +
                                  (isActive
                                    ? "text-blue-500 hover:text-blue-600"
                                    : "text-gray-700 hover:text-gray-500")
                                }
                                to={item.path}
                              >
                                <i className={item.icon + " mr-2 text-sm "}></i>{" "}
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              {user &&
                user.role === "ADMIN" &&
                Object.keys(ADMIN_MENU_ITEMS).map((key) => {
                  return (
                    <div key={key}>
                      <hr className="my-4 md:min-w-full" />
                      <h6 className="md:min-w-full text-left text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                        {key}
                      </h6>
                      <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                        {ADMIN_MENU_ITEMS[key].map((item) => {
                          return (
                            <li className="inline-flex">
                              <NavLink
                                className={(isActive) =>
                                  "text-xs uppercase py-3 font-bold block " +
                                  (isActive
                                    ? "text-blue-500 hover:text-blue-600"
                                    : "text-gray-700 hover:text-gray-500")
                                }
                                to={item.path}
                              >
                                <i className={item.icon + " mr-2 text-sm "}></i>{" "}
                                {item.name}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
            </>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Sidebar;
