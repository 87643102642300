import React, { useState, useEffect, useRef } from "react";
import "./statistics.scss";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";

function Statistics(props) {
  const [statistics, setStatistics] = useState({});
  const tableRef = useRef(null);
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(true);

  var totalFigures = {
    BadgeHolderAlloted: 0,
    BadgeHolderReported: 0,
    HelperAlloted: 0,
    HelperReported: 0,
    OutstationHelperAlloted: 0,
    OutstationHelperReported: 0,
    TotalAlloted: 0,
    TotalReported: 0,
  };
  var genderTotalFigures = {
    BadgeHolderAlloted: 0,
    BadgeHolderReported: 0,
    HelperAlloted: 0,
    HelperReported: 0,
    OutstationHelperAlloted: 0,
    OutstationHelperReported: 0,
    TotalAlloted: 0,
    TotalReported: 0,
  };

  useEffect(() => {
    if (props.city !== city) {
      setLoading(true);
      setCity(props.city);
    }
  }, [props]);
  useEffect(() => {
    if (city === "") {
      return;
    }
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/summary/citysummary",
        {
          city: city,
        }
      )
      .then((response) => {
        setStatistics(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [city]);
  return (
    <>
      <div className="grid grid-cols-12 gap-5 items-center">
        <div className="text-3xl uppercase col-span-12">
          Zone Wise Summary {city}
        </div>
        {!loading ? (
          <>
            <div className="col-span-12 text-right items-center">
              <ReactHTMLTableToExcel
                filename="Zone Wise Summary"
                sheet="Zone Wise Summary"
                table="table1"
                buttonText="  Excel"
                className="fas fa-download text-green-600"
              >
                <button>
                  <img
                    src="/images/icons/excel.png"
                    alt="Excel"
                    className="h-5 w-5 cursor-pointer"
                  />{" "}
                </button>
              </ReactHTMLTableToExcel>
            </div>
            <div className="col-span-12">
              <table ref={tableRef} id="table1" className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-200">
                    <th>Zone Name</th>
                    <th>BH Alloted</th>
                    <th>BH Reported</th>
                    <th>Reporting %age</th>
                    <th>Helper Alloted</th>
                    <th>Helper Reported</th>
                    <th>Reporting %age</th>
                    <th>OS Helper Alloted</th>
                    <th>OS Helper Reported</th>
                    <th>Reporting %age</th>
                    <th>Total Alloted</th>
                    <th>Total Reported</th>
                    <th>Reporting %age</th>
                  </tr>
                </thead>
                <tbody>
                  {statistics &&
                    Object.keys(statistics).map((gender, index) => {
                      return (
                        (genderTotalFigures = {
                          BadgeHolderAlloted: 0,
                          BadgeHolderReported: 0,
                          HelperAlloted: 0,
                          HelperReported: 0,
                          OutstationHelperAlloted: 0,
                          OutstationHelperReported: 0,
                          TotalAlloted: 0,
                          TotalReported: 0,
                        }),
                        (
                          <>
                            <tr className="bg-gray-200">
                              <th colSpan="14" className="text-xl p-2">
                                {gender}
                              </th>
                            </tr>
                            {statistics[gender].map((zone, index) => {
                              totalFigures.BadgeHolderAlloted +=
                                zone.BadgeHolderAlloted;
                              totalFigures.BadgeHolderReported +=
                                zone.BadgeHolderReported;
                              totalFigures.HelperAlloted += zone.HelperAlloted;
                              totalFigures.HelperReported +=
                                zone.HelperReported;
                              totalFigures.OutstationHelperAlloted +=
                                zone.OutstationHelperAlloted;
                              totalFigures.OutstationHelperReported +=
                                zone.OutstationHelperReported;
                              totalFigures.TotalAlloted += zone.TotalAlloted;
                              totalFigures.TotalReported += zone.TotalReported;
                              genderTotalFigures.BadgeHolderAlloted +=
                                zone.BadgeHolderAlloted;
                              genderTotalFigures.BadgeHolderReported +=
                                zone.BadgeHolderReported;
                              genderTotalFigures.HelperAlloted +=
                                zone.HelperAlloted;
                              genderTotalFigures.HelperReported +=
                                zone.HelperReported;
                              genderTotalFigures.OutstationHelperAlloted +=
                                zone.OutstationHelperAlloted;
                              genderTotalFigures.OutstationHelperReported +=
                                zone.OutstationHelperReported;
                              genderTotalFigures.TotalAlloted +=
                                zone.TotalAlloted;
                              genderTotalFigures.TotalReported +=
                                zone.TotalReported;

                              return (
                                <>
                                  <tr>
                                    <td>{zone.name}</td>
                                    <td>{zone.BadgeHolderAlloted}</td>
                                    <td>{zone.BadgeHolderReported}</td>
                                    <td className="calculation">
                                      {Math.round(
                                        (zone.BadgeHolderReported /
                                          zone.BadgeHolderAlloted || 0) * 100
                                      )}{" "}
                                      %
                                    </td>
                                    <td>{zone.HelperAlloted}</td>
                                    <td>{zone.HelperReported}</td>
                                    <td className="calculation">
                                      {Math.round(
                                        (zone.HelperReported /
                                          zone.HelperAlloted || 0) * 100
                                      )}{" "}
                                      %
                                    </td>
                                    <td>{zone.OutstationHelperAlloted}</td>
                                    <td>{zone.OutstationHelperReported}</td>
                                    <td className="calculation">
                                      {Math.round(
                                        (zone.OutstationHelperReported /
                                          zone.OutstationHelperAlloted || 0) *
                                          100
                                      )}{" "}
                                      %
                                    </td>
                                    <td>{zone.TotalAlloted}</td>
                                    <td>{zone.TotalReported}</td>
                                    <td>
                                      {Math.round(
                                        (zone.TotalReported /
                                          zone.TotalAlloted || 0) * 100
                                      )}{" "}
                                      %
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                            <tr className="totalRow">
                              <td>Total</td>
                              <td>{genderTotalFigures.BadgeHolderAlloted}</td>
                              <td>{genderTotalFigures.BadgeHolderReported}</td>
                              <td className="calculation">
                                {Math.round(
                                  (genderTotalFigures.BadgeHolderReported /
                                    genderTotalFigures.BadgeHolderAlloted ||
                                    0) * 100
                                )}{" "}
                                %
                              </td>
                              <td>{genderTotalFigures.HelperAlloted}</td>
                              <td>{genderTotalFigures.HelperReported}</td>
                              <td className="calculation">
                                {Math.round(
                                  (genderTotalFigures.HelperReported /
                                    genderTotalFigures.HelperAlloted || 0) * 100
                                )}{" "}
                                %
                              </td>
                              <td>
                                {genderTotalFigures.OutstationHelperAlloted}
                              </td>
                              <td>
                                {genderTotalFigures.OutstationHelperReported}
                              </td>
                              <td className="calculation">
                                {Math.round(
                                  (genderTotalFigures.OutstationHelperReported /
                                    genderTotalFigures.OutstationHelperAlloted ||
                                    0) * 100
                                )}{" "}
                                %
                              </td>
                              <td>{genderTotalFigures.TotalAlloted}</td>
                              <td>{genderTotalFigures.TotalReported}</td>
                              <td>
                                {Math.round(
                                  (genderTotalFigures.TotalReported /
                                    genderTotalFigures.TotalAlloted || 0) * 100
                                )}{" "}
                                %
                              </td>
                            </tr>
                          </>
                        )
                      );
                    })}
                  <tr>
                    <th colSpan="14" className="text-xl p-2">
                      Department Total
                    </th>
                  </tr>
                  <tr className="totalRow">
                    <td>Total</td>
                    <td>{totalFigures.BadgeHolderAlloted}</td>
                    <td>{totalFigures.BadgeHolderReported}</td>
                    <td className="calculation">
                      {Math.round(
                        (totalFigures.BadgeHolderReported /
                          totalFigures.BadgeHolderAlloted || 0) * 100
                      )}{" "}
                      %
                    </td>
                    <td>{totalFigures.HelperAlloted}</td>
                    <td>{totalFigures.HelperReported}</td>
                    <td className="calculation">
                      {Math.round(
                        (totalFigures.HelperReported /
                          totalFigures.HelperAlloted || 0) * 100
                      )}{" "}
                      %
                    </td>
                    <td>{totalFigures.OutstationHelperAlloted}</td>
                    <td>{totalFigures.OutstationHelperReported}</td>
                    <td className="calculation">
                      {Math.round(
                        (totalFigures.OutstationHelperReported /
                          totalFigures.OutstationHelperAlloted || 0) * 100
                      )}{" "}
                      %
                    </td>
                    <td>{totalFigures.TotalAlloted}</td>
                    <td>{totalFigures.TotalReported}</td>
                    <td>
                      {Math.round(
                        (totalFigures.TotalReported /
                          totalFigures.TotalAlloted || 0) * 100
                      )}{" "}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h1 className="text-2xl">loading...</h1>
          </div>
        )}
      </div>
    </>
  );
}
export default Statistics;
