import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function AbsentList(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [status, setStatus] = useState("NOT RECEIVED");
  const [header, setHeader] = useState([]);
  const tableRef = React.createRef();
  const [isExportReady, setIsExportReady] = useState(false);
  useEffect(() => {
    if (props.gender !== gender) {
      setGender(props.gender);
    }
  }, [props]);
  useEffect(() => {
    console.log("Scrolling to table");
    if (tableRef.current) {
      console.log({ tableRef });
      setIsExportReady(true);
      //tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [tableRef]);
  useEffect(() => {
    if (props.status !== status) {
      setStatus(props.status);
    }
  }, [props.status]);

  useEffect(() => {
    if (gender === "" || gender === "") {
      return;
    }

    setLoading(true);
    var payload = {
      gender: gender,
    };
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/weekly/sewadar/absent",
        payload
      )
      .then((response) => {
        if (response.data.status === "success") {
          setData(JSON.parse(response.data.body).sewadarList);
        } else {
          alert(JSON.parse(response.data.body).message);
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [gender]);

  useEffect(() => {
    if (data.length > 0) {
      setHeader(Object.keys(data[0]));
    }
  }, [data]);

  const headingMap = {
    M: "Absent Gents Sewadar",
    F: "Absent Ladies Sewadar",
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-5 printable w-full">
        <div className="text-3xl uppercase col-span-12">
          {headingMap[gender]}
        </div>
        <div className="text-lg uppercase col-span-12">
          {/* Time in HH:MM */}
          Date: {new Date().toLocaleDateString()} &nbsp; Time:{" "}
          {new Date().toLocaleTimeString()}
        </div>
        {loading && <div className="col-span-12">Loading...</div>}
        {!loading && data && data.length > 0 && header && header.length > 0 && (
          <>
            <div className="col-span-12 text-right skipprint">
              {isExportReady && (
                <ReactHTMLTableToExcel
                  filename={headingMap[gender]}
                  sheet={headingMap[gender]}
                  table="table1"
                  buttonText="  Excel"
                  className="fas fa-download text-green-600"
                >
                  <button>
                    <img
                      src="/images/icons/excel.png"
                      alt="Excel"
                      className="h-5 w-5 cursor-pointer"
                    />{" "}
                  </button>
                </ReactHTMLTableToExcel>
              )}
            </div>
            <table
              className="table-auto col-span-12 border-collapse w-full"
              id="table1"
              ref={tableRef}
            >
              <thead>
                <tr>
                  <th className="border px-4 py-2">S.No.</th>
                  {header.map((item) => {
                    return (
                      <th className="border px-4 py-2" key={item}>
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr>
                      <td className="border text px-4 py-2">{index + 1}</td>

                      {header.map((key, index) => {
                        return (
                          <td className="border text px-4 py-2" key={index}>
                            {item[key]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}
export default AbsentList;
