export const PROGRAM_YEAR = new Date().getFullYear();

export function checkRole(role) {
  var roleOrder = {
    ADMIN: 1,
    HOD: 2,
    CR: 3,
    OFFICE: 4,
    ZONE_OFFICE: 5,
  };
  if (localStorage.getItem("userdata")) {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    if (roleOrder[userdata.role] <= roleOrder[role]) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
