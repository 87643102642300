import axios from "axios";
import InfoComponent from "Global/InfoComponent/InfoComponent";
import React from "react";
import { useEffect, useState } from "react";

function BadgeFromSewadar() {
  const [sewadar, setSewadar] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  function markReturned() {
    var payload = {
      sewadarBadge: sewadar,
      userId: JSON.parse(localStorage.getItem("userdata")).username,
    };
    if (sewadar !== "") {
      axios
        .post(
          "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/sewadar/returnbadge",
          payload
        )
        .then((response) => {
          setResponseStatus(response.data.status);
          console.log({ response });
          console.log(response.data.data);

          setMessage(response.data.message);
          setLoading(false);
          setSewadar("");
          document.getElementById("sewadarBadge").focus();
        })
        .catch((error) => {
          console.log(error);
          setSewadar("");
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    document.getElementById("sewadarBadge").focus();
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">
          Badges Returned by Sewadar
        </div>
        <InfoComponent
          english="Enter the badge number of the sewadar who has returned the badge."
          hindi="वापस ले आये सेवादार का बैज नंबर दर्ज करें।"
        />
        <div className="col-span-12">
          {message && (
            <div
              className={
                (responseStatus === "SUCCESS"
                  ? "bg-green-100  border-green-400 text-green-700"
                  : "bg-red-100  border-red-400 text-red-700") +
                "border px-4 py-3 rounded relative"
              }
              role="alert"
            >
              <strong className="font-bold">
                {responseStatus === "SUCCESS" ? "Success!!" : "Error!!"}
              </strong>{" "}
              <br />
              <span className="block sm:inline">{message}</span>
            </div>
          )}
        </div>
        <div className="col-span-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              markReturned();
            }}
          >
            <input
              type="text"
              placeholder="Enter Badge Number"
              value={sewadar}
              onChange={(e) => setSewadar(e.target.value)}
              className="border-2 border-gray-300 bg-white h-20 w-full px-5 pr-16 rounded-lg text-xl focus:outline-none shadow-xl"
              id="sewadarBadge"
            />
            <div className="text-sm italic text-gray-400 pl-2 mt-5">
              Press <b>Enter key</b> or click on <b>Returned</b> button to mark
              badge as returned.
            </div>
          </form>
        </div>
        <div className="col-span-12">
          {!loading ? (
            <input
              type="button"
              value="Returned"
              onClick={() => markReturned()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer w-full"
            />
          ) : (
            <img
              src="/images/loading.gif"
              alt="loading"
              className="w-10 h-10 mx-auto"
            />
          )}
        </div>
      </div>
    </>
  );
}
export default BadgeFromSewadar;
