import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";

function MarkDailyAttendance() {
  const [sewadar, setSewadar] = useState("");
  const [loading, setLoading] = useState(false);

  const [sewadarAttendance, setSewadarAttendance] = useState({});
  const [allowed, setAllowed] = useState(false);
  const history = useHistory();
  const [sewadarDetails, setSewadarDetails] = useState({});
  const [sewaDate, setSewaDate] = useState("");
  const [sewaInTime, setSewaInTime] = useState("");
  const [sewaOutTime, setSewaOutTime] = useState("");
  const [responseStatus, setResponseStatus] = useState("");

  const [items, setItems] = useState([]);
  const search = useRef(null);

  const [attendanceKey, setAttendanceKey] = useState(0);

  const roleNames = {
    CR: "Coordinator",
    ZI: "Zone Incharge",
    GI: "Group Incharge",
    NS: "Sewadar",
  };

  function markAttendance() {
    if (sewadar) {
      if (sewaDate === "" || sewaInTime === "" || sewaOutTime === "") {
        alert("Please enter all details");
        return;
      }
      setLoading(true);
      let payload = {
        sewadarBadge: sewadar,
        sewaDate: sewaDate,
        sewaInTime: sewaInTime,
        sewaOutTime: sewaOutTime,
        sewaMarkedBy: JSON.parse(localStorage.getItem("userdata")).username,
      };

      console.log({ payload });
      axios
        .post(
          "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/daily/attendance",
          payload
        )
        .then(function (response) {
          console.log(response.data);
          if (response.data.status === "Success") {
            setSewadarAttendance(JSON.parse(response.data.body));
            setResponseStatus(response.data.status);

            resetData();
          } else if (response.data.status === "Failure") {
            setSewadarAttendance(JSON.parse(response.data.body));
            setResponseStatus(response.data.status);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
      setLoading(false);
    }
  }
  useEffect(() => {
    if (localStorage.getItem("userdata")) {
      var userdata = JSON.parse(localStorage.getItem("userdata"));
      if (["ADMIN", "OFFICE"].includes(userdata.role)) {
        setAllowed(true);
        axios
          .post(
            "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/daily/sewadar"
          )
          .then(function (response) {
            console.log(response.data.body);
            if (response.data.status === "success") {
              setItems(JSON.parse(response.data.body).sewadarList);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setAllowed(false);
      }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setSewaInTime("");
    setSewaOutTime("");
  }, [sewadar]);

  function resetData() {
    setAttendanceKey(attendanceKey + 1);
    setSewadarDetails({});
    setSewadar("");
    setSewaDate("");
    setSewaInTime("");
    setSewaOutTime("");
  }

  // useEffect(() => {
  //   if (
  //     ["ADMIN", "OFFICE"].includes(
  //       JSON.parse(localStorage.getItem("userdata")).role
  //     )
  //   ) {
  //     setAllowed(true);
  //     document.getElementById("sewadarBadge").focus();
  //   } else {
  //     setAllowed(false);
  //   }
  // }, []);
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item);
    setSewadarDetails(item);
    setSewadar(item.id);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Badge: {item.id}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Name: {item.name}
        </span>
      </>
    );
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">
          Mark Daily Attendance
        </div>
        <div className="col-span-12 md:col-span-12">
          <input
            type="date"
            name="sewaDate"
            id="sewaDate"
            className="w-full border border-gray-300 rounded-md py-2 px-4"
            placeholder="Sewa Date"
            value={sewaDate}
            onChange={(e) => setSewaDate(e.target.value)}
          />
        </div>

        <div className="col-span-12 md:col-span-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <ReactSearchAutocomplete
              key={attendanceKey}
              ref={search}
              items={items}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
              placeholder="Search Sewadar"
              fuseOptions={{
                keys: ["name"],
                threshold: 0.2,
              }}
            />
          </form>
        </div>
        {Object.keys(sewadarDetails).length > 0 && (
          <div className="col-span-12">
            <form>
              <div className="grid grid-cols-12 gap-5 items-center">
                <div className="col-span-12 md:col-span-2">In Time </div>
                <div className="col-span-12 md:col-span-4">
                  <input
                    type="time"
                    name="sewaInTime"
                    id="sewaOutTime"
                    className="w-full border border-gray-300 rounded-md py-2 px-4"
                    placeholder="Sewa Date"
                    value={sewaInTime}
                    onChange={(e) => setSewaInTime(e.target.value)}
                  />
                </div>
                <div className="col-span-12 md:col-span-2">Out Time </div>
                <div className="col-span-12 md:col-span-4">
                  <input
                    type="time"
                    name="sewaOutTime"
                    id="sewaOutTime"
                    className="w-full border border-gray-300 rounded-md py-2 px-4"
                    placeholder="Sewa Date"
                    value={sewaOutTime}
                    onChange={(e) => setSewaOutTime(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="col-span-12 md:col-span-12">
          {!loading ? (
            <input
              type="button"
              value="Mark Attendance"
              onClick={() => markAttendance()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 md:h-12 rounded-3xl cursor-pointer w-full shadow-xl"
            />
          ) : (
            <img
              src="/images/loading.gif"
              alt="loading"
              className="w-10 h-10 mx-auto"
            />
          )}
        </div>

        {Object.keys(sewadarAttendance).length > 0 && (
          <div className="col-span-12">
            {/* Set background is Green if the responseStatus is Success else red  */}
            <div className="grid grid-cols-12 gap-5 items-center ">
              <div
                className={
                  "col-span-12 p-2 " +
                  (responseStatus === "Success"
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white")
                }
              >
                {sewadarAttendance.message && (
                  <div className="col-span-12">{sewadarAttendance.message}</div>
                )}
              </div>

              <div className="col-span-12 md:col-span-2">
                Sewadar Badge and Name
              </div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.sewadarBadge} -{" "}
                {sewadarAttendance.sewadarName}
              </div>

              <div className="col-span-12 md:col-span-2">Sewa Date</div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {/* Format in dd-mm-yyyy */}
                {sewadarAttendance.sewaDate}
              </div>
              <div className="col-span-12 md:col-span-2">
                Sewa In Time and Out Time
              </div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.sewaInTime} - {sewadarAttendance.sewaOutTime}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default MarkDailyAttendance;
