import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from "./Auth/Login"
import Logout from './Auth/Logout';


ReactDOM.render(
  <React.StrictMode>
    <Router>
    <Switch>
      <Route exact path="/" >
        <Login/>
      </Route>
      <Route exact path="/logout" >
        <Logout/>
      </Route>
      <Route path="/admin">
        <App />
      </Route>
    </Switch>  
    </Router>
    
  </React.StrictMode>,
  document.getElementById('root')
);


reportWebVitals();
