function InfoComponent(props) {
  return (
    <div className="col-span-12 text-xl text-gray-500 p-5 border-2 border-gray-300 rounded-md">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 md:col-span-1 text-2xl uppercase">
          <span className="fas fa-info-circle text-3xl"></span>
        </div>
        <div className="col-span-12 md:col-span-11 text-2xl uppercase">
          <div className="">
            {props.english} <br />
            {props.hindi}
          </div>
        </div>
      </div>
    </div>
  );
}
export default InfoComponent;
