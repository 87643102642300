import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Dashboard from "./views/Dashboard";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Traffic Department Admin Panel";
  }, []);
  return (
    <div className="App">
      <Router>
        <Route path="/admin" component={Dashboard}></Route>
      </Router>
    </div>
  );
}

export default App;
