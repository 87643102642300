import { useHistory } from "react-router";
function Logout() {
    const history = useHistory();
    localStorage.clear();
    history.push("/");

    return (
        <>
        </>
    )
}

export default Logout