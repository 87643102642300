import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

function PointReport(props) {
  const [loading, setLoading] = useState(false);
  const [pointDetails, setPointDetails] = useState({});
  const [pointKeys, setPointKeys] = useState([]);

  const [zone, setZone] = useState(-1);

  useEffect(() => {
    if (localStorage.getItem("userdata")) {
      var userdata = JSON.parse(localStorage.getItem("userdata"));
      if (["ADMIN", "OFFICE"].includes(userdata.role)) {
        setZone(0);
      }
      if (["HOD", "CR", "ZI"].includes(userdata.role)) {
        setZone(0);
      }
      if ("ZONE_OFFICE" === userdata.role) {
        setZone(userdata.zone);
      }
    }
  }, []);

  useEffect(() => {
    if (
      props.zone !== undefined &&
      props.zone !== null &&
      props.zone !== "" &&
      props.zone !== zone
    ) {
      setZone(props.zone);
    }
  }, [props, zone]);

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/weekly/reports/pointreport",
        {
          zoneId: zone,
        }
      )
      .then(function (response) {
        console.log(response);
        if (response.data.status === "Success") {
          console.log(JSON.parse(response.data.body));
          setPointDetails(JSON.parse(response.data.body));
        } else if (response.data.status === "Failure") {
          alert(JSON.parse(response.data.body).message);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
    setLoading(false);
  }, [zone]);
  useEffect(() => {
    if (pointDetails && Object.keys(pointDetails).length > 0) {
      setPointKeys(Object.keys(pointDetails));
    }
  }, [pointDetails]);

  return (
    <>
      {loading ? (
        <>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-12 gap-5 printable w-full">
          <div className="text-3xl uppercase col-span-12">Point Report</div>
          <div className="text-lg uppercase col-span-12">
            {/* Time in HH:MM */}
            Date: {new Date().toLocaleDateString()} &nbsp; Time:{" "}
            {new Date().toLocaleTimeString()}
          </div>
          <div className="col-span-12">
            {pointKeys.length > 0 &&
              pointKeys.map((key) => {
                return (
                  <>
                    <div className="col-span-12 my-10">
                      <div className="text-3xl uppercase">{key}</div>
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 gap-5">
                        {pointDetails &&
                          Object.keys(pointDetails[key]).length > 0 &&
                          pointDetails[key].map((point) => {
                            return (
                              <>
                                <PointCard point={point} />
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

function PointCard(props) {
  //   {
  //     "sewadarBadge": row["sewadarBadge"],
  //     "sewadarName": row["sewadarName"],
  //     "sewadarReportingTime": row["sewadarReportingTime"],
  //     "pointName": row["pointName"],
  //     "jacketNo": row["jacketNo"]

  // }

  return (
    <>
      <div className="col-span-12 md:col-span-4 lg:col-span-3 flex-wrap">
        <div
          className={
            "shadow-md rounded-lg p-4 " +
            (props.point.sewadarBadge !== null
              ? "bg-green-700 text-white"
              : "bg-gray-300")
          }
        >
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="lg:text-lg uppercase font-bold">
                {props.point.pointName}
              </div>
            </div>
            <div className="col-span-12">
              <div className="text-lg uppercase">
                {props.point.sewadarBadge}
              </div>
              <div className="col-span-12">
                <div className="text-lg uppercase">
                  {props.point.sewadarName}
                </div>
              </div>
              <div className="col-span-12">
                <div className="text-lg uppercase">
                  {props.point.sewadarPhone !== null
                    ? "phone: " + props.point.sewadarPhone
                    : ""}
                </div>
              </div>

              <div className="col-span-12">
                <div className="text-lg uppercase">
                  {props.point.sewadarReportingTime}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PointReport;
