import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./style.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorshow, setError] = useState(false);
  const history = useHistory();

  if (localStorage.getItem("userdata")) {
    var userdata = JSON.parse(localStorage.getItem("userdata"));
    if (["ADMIN", "OFFICE"].includes(userdata.role)) {
      history.push("/admin/report-sewadars");
    }
    if (["HOD", "CR", "ZI"].includes(userdata.role)) {
      history.push("admin/pointreport");
    }
    if ("ZONE_OFFICE" === userdata.role) {
      history.push("/admin/pointreport");
    }
  }

  function checkLogin(e) {
    e.preventDefault();
    let data = { username: username, password: password };
    setError(false);

    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/auth/checklogin",
        data
      )
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          localStorage.setItem(
            "userdata",
            JSON.stringify(response.data.userdata)
          );
          localStorage.setItem("reportCity", "Indore");
          if (
            response.data.userdata.role === "ADMIN" ||
            response.data.userdata.role === "HOD" ||
            response.data.userdata.role === "CR"
          ) {
            history.push("/admin/pointreport");
          }
          if (response.data.userdata.role === "OFFICE") {
            history.push("/admin/report-sewadars");
          }
          if (response.data.userdata.role === "ZONE_OFFICE") {
            history.push("/admin/pointreport");
          }
        } else if (response.data.status === "FAILURE") {
          setError(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className="container mx-auto px-4 h-screen">
        <div className="content-center items-center justify-center h-full">
          <div className="mx-auto lg:w-3/12 mt-20 mb-10">
            <img src="" className="mx-auto" />
          </div>
          <div className="mx-auto lg:w-3/12 my-10 text-2xl text-center uppercase">
            Traffic Department <br /> Weekly Satsang
          </div>

          <div className="w-full lg:w-4/12 px-4 mx-auto ">
            <div
              className={
                "bg-red-700 p-3 my-3 rounded-xl text-center text-white " +
                (errorshow ? "" : "errorhide")
              }
            >
              Incorrect Username or Password!
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-gray-400 text-center my-3 font-bold">
                  <small>Sign in with credentials</small>
                </div>
                <form onSubmit={(e) => checkLogin(e)}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-gray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-600">
                        Remember me
                      </span>
                    </label>
                  </div> */}

                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-800 text-white active:bg-gray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
