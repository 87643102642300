import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminNavBar from "../../Global/AdminNavBar";
import Sidebar from "../../Global/Sidebar";
import ErrorMessage from "../../Global/ErrorMessage";
import ReportSewadar from "../../Components/ReportSewadar";
import BadgeFromSewasamiti from "Components/Badges/BadgeFromSewasamiti";
import BadgeFromSewadar from "Components/Badges/BadgeFromSewadar";
import ZoneWiseSummary from "Components/ZoneWiseSummary";
import Statistics from "Components/Statistics";
import Reports from "Components/Reports";
import BadgeToSewasamiti from "Components/Badges/BadgeToSewasamiti";
import ChangeCity from "Components/Misc/ChangeCity";
import SewasamitiReport from "Components/Reports/SewasamitiReport";
import ZoneWiseReporting from "Components/ZoneWiseReporting";
import ReportFormat from "Components/Reports/ReportFormat";
import CityWiseReports from "Components/Reports/CityWiseReports";
import WeeklyAttendance from "Components/Reports/WeeklyAttendance";
import AbsentList from "Components/Reports/AbsentList";
import LeaveList from "Components/Reports/LeaveList";
import InchargeDetails from "Components/Reports/InchargeDetails";
import SewadarLeave from "Components/SewadarLeave";
import WeeklyStatistics from "Components/Reports/Statistics";
import PointReport from "Components/Reports/PointReport";
import MarkDailyAttendance from "Components/Daily/MarkDailyAttendance";
import DailyAttendanceReport from "Components/Daily/DailyAttendanceReport";
function Dashboard() {
  return (
    <>
      <Router>
        <Sidebar />
        <div className="relative md:ml-64 bg-blueGray-100 text-left">
          <AdminNavBar />
          <ErrorMessage />
          {/* Header */}

          <div className="text-left p-6">
            <Switch>
              <Route
                exact
                path="/admin/report-sewadars"
                component={ReportSewadar}
              />
              <Route
                exact
                path="/admin/badge-from-sewasamiti"
                component={BadgeFromSewasamiti}
              />
              <Route
                exact
                path="/admin/badge-from-sewadar"
                component={BadgeFromSewadar}
              />
              <Route
                exact
                path="/admin/badge-to-sewasamiti"
                component={BadgeToSewasamiti}
              />
              <Route
                exact
                path="/admin/zonewise-summary"
                component={ZoneWiseSummary}
              />
              <Route exact path="/admin/change-city" component={ChangeCity} />
              <Route exact path="/admin/statistics-indore">
                <Statistics city="Indore" />
              </Route>
              <Route exact path="/admin/statistics-biaora">
                <Statistics city="Biaora" />
              </Route>
              <Route exact path="/admin/statistics-common">
                <Statistics city="common" />
              </Route>
              <Route exact path="/admin/absent-list">
                <ReportFormat reportType="Absent" />
              </Route>
              <Route exact path="/admin/reported-list">
                <ReportFormat reportType="Present" />
              </Route>
              <Route exact path="/admin/master-list">
                <ReportFormat reportType="Master" />
              </Route>
              <Route exact path="/admin/master-report">
                <ReportFormat reportType="MasterReport" />
              </Route>
              <Route exact path="/admin/badges-not-recieved">
                <SewasamitiReport status={"NOT RECEIVED"} />
              </Route>
              <Route exact path="/admin/badges-recieved">
                <SewasamitiReport status={"RECEIVED"} />
              </Route>
              <Route exact path="/admin/badges-returned">
                <SewasamitiReport status={"RETURNED"} />
              </Route>
              <Route exact path="/admin/badges-not-returned">
                <Reports report="badgesNotReturned" />
              </Route>
              <Route exact path="/admin/master-lists">
                <Reports report="masterList" />
              </Route>
              <Route exact path="/admin/attendance-format">
                <Reports report="attendanceFormat" />
              </Route>
              <Route exact path="/admin/reported-sewadars">
                <ZoneWiseReporting />
              </Route>
              <Route exact path="/admin/indore-outstation">
                <CityWiseReports />
              </Route>
              <Route exact path="/admin/weekly-attendance-gents">
                <WeeklyAttendance gender={"M"} />
              </Route>
              <Route exact path="/admin/weekly-attendance-gents-training">
                <WeeklyAttendance gender={"M"} reportType={"training"} />
              </Route>
              <Route exact path="/admin/weekly-attendance-ladies">
                <WeeklyAttendance gender={"F"} />
              </Route>
              <Route exact path="/admin/weekly-absent-gents">
                <AbsentList gender={"M"} />
              </Route>

              <Route exact path="/admin/weekly-absent-ladies">
                <AbsentList gender={"F"} />
              </Route>
              <Route exact path="/admin/weekly-leave-gents">
                <LeaveList gender={"M"} />
              </Route>
              <Route exact path="/admin/weekly-leave-ladies">
                <LeaveList gender={"F"} />
              </Route>
              <Route exact path="/admin/weekly-incharge-details">
                <InchargeDetails gender={"M"} />
              </Route>
              <Route exact path="/admin/weekly-mark-leave">
                <SewadarLeave gender={"M"} />
              </Route>
              <Route exact path="/admin/statistics">
                <WeeklyStatistics />
              </Route>
              <Route exact path="/admin/pointreport">
                <PointReport />
              </Route>

              <Route exact path="/admin/mark-daily-attendance">
                <MarkDailyAttendance />
              </Route>
              <Route exact path="/admin/daily-attendance-report">
                <DailyAttendanceReport />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </>
  );
}
export default Dashboard;
