import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import axios from "axios";

function WeeklyStatistics() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/weekly/reports/statistics"
      )
      .then(function (response) {
        if (response.data.status === "Success") {
          console.log(response.data.body);
          console.log(JSON.parse(response.data.body));
          setData(JSON.parse(response.data.body));
          console.log(Object.keys(JSON.parse(response.data.body)["M"][0]));
          setHeader(Object.keys(JSON.parse(response.data.body)["M"][0]));
          setLoading(false);
        } else if (response.data.status === "Failure") {
          alert(JSON.parse(response.data.body).message);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 gap-5 printable">
        <div className="text-3xl uppercase col-span-12">Roster statistics</div>
        <div className="text-lg uppercase col-span-12">
          {/* Time in HH:MM */}
          Date: {new Date().toLocaleDateString()} &nbsp; Time:{" "}
          {new Date().toLocaleTimeString()}
        </div>
        {loading && <div className="col-span-12">Loading...</div>}
        {!loading && data && (
          <>
            <div className="col-span-12 text-right skipprint">
              {
                <ReactHTMLTableToExcel
                  filename={"Weekly Statistics"}
                  sheet={"Weekly Statistics"}
                  table="table1"
                  buttonText="  Excel"
                  className="fas fa-download text-green-600"
                >
                  <button>
                    <img
                      src="/images/icons/excel.png"
                      alt="Excel"
                      className="h-5 w-5 cursor-pointer"
                    />{" "}
                  </button>
                </ReactHTMLTableToExcel>
              }
            </div>
            <table
              className="table-auto col-span-12 border-collapse"
              id="table1"
            >
              <thead>
                <tr>
                  <th className="border px-4 py-2" colSpan={header.length}>
                    Male
                  </th>
                </tr>
                <tr>
                  {header.map((item) => {
                    return (
                      <th className="border px-4 py-2" key={item}>
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data &&
                  data["M"] &&
                  data["M"].length > 0 &&
                  data["M"].map((item, index) => {
                    return (
                      <tr
                        key={item["zoneId"]}
                        className={
                          index === data["M"].length - 1
                            ? "font-bold text-green-600"
                            : ""
                        }
                      >
                        {header.map((key) => {
                          return (
                            <td
                              className={
                                "border px-4 py-2 " +
                                (key === "Zone Name" ? "text" : "")
                              }
                              key={key}
                            >
                              {item[key]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                <tr>
                  <th className="border px-4 py-2" colSpan={header.length}>
                    Female
                  </th>
                </tr>
                {data &&
                  data["F"] &&
                  data["F"].length > 0 &&
                  data["F"].map((item, index) => {
                    return (
                      <tr
                        key={item["zoneId"]}
                        className={
                          index === data["F"].length - 1
                            ? "font-bold text-green-600"
                            : ""
                        }
                      >
                        {header.map((key) => {
                          return (
                            <td
                              className={
                                "border px-4 py-2 " +
                                (key === "Zone Name" ? "text" : "")
                              }
                              key={key}
                            >
                              {item[key]}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
        {!loading && data && data.length === 0 && (
          <div className="col-span-12">No data found</div>
        )}
      </div>
    </>
  );
}
export default WeeklyStatistics;
