import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import InfoComponent from "../../Global/InfoComponent/InfoComponent";

function BadgeFromSewasamiti(props) {
  const [sewadar, setSewadar] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [city, setCity] = useState("");
  const [user, setUser] = useState("");
  const history = useHistory();
  const [responseStatus, setResponseStatus] = useState("");

  function markRecieved() {
    setLoading(true);
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/sewasamiti/recieve",
        {
          sewadarBadge: sewadar,
          city: city,
          userId: user,
        }
      )
      .then((response) => {
        setResponseStatus(response.data.status);
        console.log({ response });
        console.log(response.data.data);

        setMessage(response.data.message);
        setLoading(false);
        setSewadar("");
        document.getElementById("sewadarBadge").focus();
      })
      .catch((error) => {
        console.log(error);
        setSewadar("");
        setLoading(false);
      });
  }

  useEffect(() => {
    var userdata = JSON.parse(localStorage.getItem("userdata"));
    console.log("#############################################");
    console.log({ userdata });
    if (userdata) {
      if (["ADMIN", "OFFICE"].includes(userdata.role)) {
        var city = localStorage.getItem("reportCity");
        setCity(city);
        setUser(userdata.username);
      } else {
        history.push("/logout");
      }
    }
    document.title = "Badge Received From Sewasamiti";
    // focus on the input field
    document.getElementById("sewadarBadge").focus();
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">
          Badge Received From Sewasamiti
        </div>
        <InfoComponent
          english={
            "Use this form to enter the badges received from the Sewa Samiti."
          }
          hindi={"सेवा समिति से मिले बैजेस की एंट्री इस फॉर्म से करें।"}
        />

        <div className="col-span-12">
          {message && (
            <div
              className={
                (responseStatus === "SUCCESS"
                  ? "bg-green-100  border-green-400 text-green-700"
                  : "bg-red-100  border-red-400 text-red-700") +
                "border px-4 py-3 rounded relative"
              }
              role="alert"
            >
              <strong className="font-bold">
                {responseStatus === "SUCCESS" ? "Success!!" : "Error!!"}
              </strong>{" "}
              <br />
              <span className="block sm:inline">{message}</span>
            </div>
          )}
        </div>
        <div className="col-span-12">
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              markRecieved();
            }}
          >
            <input
              type="text"
              placeholder="Enter Badge Number"
              value={sewadar}
              onChange={(e) => setSewadar(e.target.value.toUpperCase())}
              className="border-2 border-gray-300 bg-white h-20 w-full px-5 pr-16 rounded-lg text-xl focus:outline-none shadow-xl"
              id="sewadarBadge"
              style={{ textTransform: "uppercase" }}
            />
            <div className="text-sm italic text-gray-400 pl-2 mt-5">
              Press <b>Enter key</b> or click on <b>Mark Recieved</b> button to
              mark badge as recieved.
            </div>
          </form>
        </div>

        <div className="col-span-12">
          {!loading ? (
            <input
              type="button"
              value="Mark Recieved"
              onClick={() => markRecieved()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer w-full "
            />
          ) : (
            <img
              src="/images/loading.gif"
              alt="loading"
              className="w-10 h-10 mx-auto"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default BadgeFromSewasamiti;
