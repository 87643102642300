import { useState, useEffect } from "react";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { list } from "postcss";

function AbsentList(props) {
  const [sewadarList, setSewadarList] = useState({});
  const [loading, setLoading] = useState(false);
  const [zoneName, setZoneName] = useState("");
  const [reportType, setReportType] = useState("");
  const [titles, setTitles] = useState(["S.No."]);
  const [count, setCount] = useState(0);

  var titleMap = {
    Absent: "Absent List",
    Present: "Reported List",
    Master: "Master List",
    MasterReport: "Master Reported List",
  };

  // sewadarList = {
  //   "Traffic (Group 1)": [
  //     {
  //       sewadarBadge: "92405",
  //       sewadarName: "Gopalsingh H. Gurjar",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "88662",
  //       sewadarName: "RAKESH KALESHRIYA",
  //       sewadarGender: "M",
  //       sewadarStatus: "HELPER",
  //       sewadarRole: "",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00511",
  //       sewadarName: "Anoopsingh J. Sisodiya",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00599",
  //       sewadarName: "Babulal P. Malviya",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG00300",
  //       sewadarName: "Banesingh B. Lawar",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM24544",
  //       sewadarName: "Biramsingh Verma",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00385",
  //       sewadarName: "Gopal B. Bhilala",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG00277",
  //       sewadarName: "Gorilal P. Ruhela",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00203",
  //       sewadarName: "Kantaprasad B. Patidar",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BF02003",
  //       sewadarName: "Kaushalyabai R. Rajput",
  //       sewadarGender: "F",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00655",
  //       sewadarName: "Lakhanlal T. Dhangar",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG00227",
  //       sewadarName: "Laxman D. Tanwar",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM01875",
  //       sewadarName: "Puranmal K. Bhatia",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BF00252",
  //       sewadarName: "Pushpabai M. Gehlot",
  //       sewadarGender: "F",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00113",
  //       sewadarName: "Rameshchandra P. Malviya",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00286",
  //       sewadarName: "Ramlal D. Malviya",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00073",
  //       sewadarName: "Ramlal N. Solanki",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG02249",
  //       sewadarName: "Sahukarsingh C. Khinchi",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM24608",
  //       sewadarName: "Sandeep Lovevanshi",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG01617",
  //       sewadarName: "Sanjaykumar R. Dangi",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BG00133",
  //       sewadarName: "Shivnarayan M. Dangi",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM00037",
  //       sewadarName: "Shivsingh B. Bagdawat",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BL01635",
  //       sewadarName: "Sushiladevi A. Tanwar",
  //       sewadarGender: "F",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //     {
  //       sewadarBadge: "BM01887",
  //       sewadarName: "Vijaysingh G. Choudhary",
  //       sewadarGender: "M",
  //       sewadarStatus: "PERMANENT",
  //       sewadarRole: "NS",
  //       sewadarIndoreGroup: null,
  //       sewadarBiaoraGroup: 52,
  //       sewadarGroup: "Traffic (Group 1)",
  //     },
  //   ],
  // };
  useEffect(() => {
    if (props.reportType) {
      if (props.reportType !== reportType) {
        setReportType(props.reportType);
      }
    } else {
      setReportType("Master");
    }
  }, [props.reportType]);
  useEffect(() => {
    if (reportType !== "") {
      setLoading(true);
      var data = {
        zoneId: JSON.parse(localStorage.getItem("userdata")).zone || props.zone,
        listType: reportType,
      };
      console.log(data);
      axios
        .post(
          "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/reports/get",
          data
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "SUCCESS") {
            setSewadarList(response.data.data);
            var key = Object.keys(response.data.data)[0];

            setTitles(Object.keys(response.data.data[key][0]));
            setCount(response.data.count);
            setZoneName(response.data.zoneName);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSewadarList({});
          setCount(0);
          setLoading(false);
        });
    }
  }, [reportType]);

  return (
    <div className="w-full">
      <div className="text-3xl uppercase col-span-12 mt-5">
        {titleMap[props.reportType]}
      </div>
      <div className="text-xl uppercase col-span-12 my-5">Zone: {zoneName}</div>
      <div className="text-xl uppercase col-span-12 my-5">
        Total Count: {count}
      </div>

      {loading && <div>Loading...</div>}
      {!loading && sewadarList && Object.keys(sewadarList).length > 0 && (
        <>
          <div className="w-full text-right">
            <ReactHTMLTableToExcel
              filename={titleMap[reportType]}
              sheet={titleMap[reportType]}
              table="table-1"
              buttonText="  Excel"
              className="fas fa-download text-green-600"
            >
              <button>
                <img
                  src="/images/icons/excel.png"
                  alt="Excel"
                  className="h-5 w-5 cursor-pointer"
                />{" "}
              </button>
            </ReactHTMLTableToExcel>
          </div>
          <table className="w-full" id="table-1">
            <thead>
              <tr>
                <th className="border border-black">S.No.</th>
                {titles.map((title) => (
                  <th className="border border-black">{title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(sewadarList).map((key) => (
                <>
                  {" "}
                  <tr>
                    <td
                      className="border border-black"
                      colSpan={titles.length + 1}
                    >
                      {key}
                    </td>
                  </tr>
                  {sewadarList[key].map((sewadar, idx) => (
                    <tr>
                      <td className="border border-black">{idx + 1}</td>
                      {titles.map((title) => (
                        <td className="border border-black text">
                          {sewadar[title]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
export default AbsentList;
