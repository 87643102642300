import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReportFormat from "./ReportFormat";

import BadgesNotReceived from "./SewasamitiReport";
import BadgesNotReturned from "./BadgesNotReturned";
import MasterList from "./MasterList";
import AttendanceFormat from "./AttendanceFormat";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Reports(props) {
  const { id } = useParams();
  const [report, setReport] = useState("");
  const [city, setCity] = useState("");
  const [zones, setZones] = useState([]);
  const [zone, setZone] = useState(0);
  const [loading, setLoading] = useState(false);

  var reportMapping = {
    absentList: { name: "Absent List", component: "AbsentList" },
    badgesNotReceived: {
      name: "Badges Not Received",
      component: "BadgesNotReceived",
    },
    badgesNotReturned: {
      name: "Badges Not Returned",
      component: "BadgesNotReturned",
    },
    masterList: { name: "Master List", component: "MasterList" },
    attendanceFormat: {
      name: "Attendance Format",
      component: "AttendanceFormat",
    },
  };

  useEffect(() => {
    var reportCity = localStorage.getItem("reportCity");
    setCity(reportCity);
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/zones/list",
        { city: reportCity }
      )
      .then((response) => {
        setZones(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (props.report !== report) {
      setReport(props.report);
      setZone(0);
    }
  }, [props.report]);
  return (
    <>
      {report && city && (
        <div className="grid grid-cols-12 gap-5">
          <div className="text-2xl uppercase col-span-12">
            {reportMapping[report].name}
          </div>

          <div className="col-span-12 md:col-span-12">
            <select
              className="w-full border border-gray-300 rounded-md px-4 py-2 outline-none focus:ring-2 focus:ring-blue-600"
              onChange={(e) => setZone(e.target.value)}
              value={zone}
            >
              <option value="0">Select Zone</option>
              {zones.map((zone) => (
                <option value={zone.id} key={zone.id}>
                  {zone.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-12">
            {zone &&
              reportMapping[report].component &&
              (reportMapping[report].component === "AbsentList" ? (
                <ReportFormat zone={zone} reportType={"Absent"} />
              ) : reportMapping[report].component === "BadgesNotReceived" ? (
                <BadgesNotReceived zone={zone} />
              ) : reportMapping[report].component === "BadgesNotReturned" ? (
                <BadgesNotReturned zone={zone} />
              ) : reportMapping[report].component === "MasterList" ? (
                <ReportFormat zone={zone} reportType={"Master"} />
              ) : reportMapping[report].component === "AttendanceFormat" ? (
                <ReportFormat zone={zone} reportType={"Attendance"} />
              ) : reportMapping[report].component === "Present" ? (
                <ReportFormat zone={zone} reportType={"Present"} />
              ) : (
                <div>Invalid Report</div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
export default Reports;
