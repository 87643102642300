import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function UserDropdown(props) {
  const [userdata, setUserdata] = useState({});
  const [city, setCity] = useState("");
  useEffect(() => {
    if (localStorage.getItem("userdata")) {
      setUserdata(JSON.parse(localStorage.getItem("userdata")));
      setCity(localStorage.getItem("reportCity"));
    } else {
      // Redirect to login page
      window.location.href = "/";
    }
  }, []);

  return (
    <>
      Welcome,&nbsp;<b>{userdata.name} </b> &nbsp;
      {/* | &nbsp; City:&nbsp;
      <b>{city} </b> &nbsp;{" "}
      <Link to={"/admin/change-city"}>
        <i class="fas fa-sync" />
      </Link>{" "}
      &nbsp; | &nbsp; Role:&nbsp;
      <b>{userdata.role} </b> &nbsp; */}
      | &nbsp;
      <a href="/logout"> Logout</a>
    </>
  );
}
export default UserDropdown;
