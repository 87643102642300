import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function ChangeCity(props) {
  const [city, setCity] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("reportCity")) {
      setCurrentCity(localStorage.getItem("reportCity"));
      setCity(localStorage.getItem("reportCity"));
    } else {
      setCity("NoCity");
    }
  }, []);

  function handleChange(e) {
    localStorage.setItem("reportCity", e);
    alert("City changed to " + e);
    //history.push("/admin/zonewise-summary?reload=true");
    window.location.reload();
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleChange(city);
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="text-3xl uppercase col-span-12">Change City</div>
      <div className="col-span-12">
        <form onSubmit={handleSubmit}>
          <select
            className="border-2 border-gray-300 bg-white h-20 w-full px-5 pr-16 rounded-lg text-xl focus:outline-none"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          >
            {currentCity === "NoCity" && (
              <option value="NoCity">Select City</option>
            )}
            <option value="Indore">Indore</option>
            <option value="Biaora">Biaora</option>
          </select>
          {city !== "NoCity" && city !== currentCity && (
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer w-full mt-5"
            >
              Update City
            </button>
          )}
        </form>
      </div>
    </div>
  );
}
export default ChangeCity;
