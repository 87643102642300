import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";

function DailyAttendanceReport(props) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sewadarBadge, setSewadarBadge] = useState("");
  const [reportData, setReportData] = useState([{}]);

  const [loading, setLoading] = useState(false);

  function fetch_report() {
    if (fromDate !== "" && toDate !== "") {
      setLoading(true);
      axios
        .post(
          "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/daily/attendance/report",
          {
            fromDate: fromDate,
            toDate: toDate,
            sewadarBadge: sewadarBadge,
          }
        )
        .then(function (response) {
          if (response.data.status === "Success") {
            setReportData(JSON.parse(response.data.body));
          } else if (response.data.status === "Failure") {
            alert(JSON.parse(response.data.body).message);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <label>From Date</label>
          <input
            type="date"
            className="form-control"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </div>
        <div className="col-md-3">
          <label>To Date</label>
          <input
            type="date"
            className="form-control"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </div>
        <div className="col-md-3">
          <label>Sewadar Badge</label>
          <input
            type="text"
            className="form-control"
            value={sewadarBadge}
            onChange={(e) => {
              setSewadarBadge(e.target.value);
            }}
          />
        </div>
        <div className="col-md-3">
          <label>&nbsp;</label>
          <button
            className="btn btn-primary"
            onClick={() => {
              fetch_report();
            }}
          >
            Fetch Report
          </button>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-md-12">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sewadar Badge</th>
                <th>Sewadar Name</th>
                <th>Date</th>
                <th>In Time</th>
                <th>Out Time</th>
                <th>Marked By</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.sewadarBadge}</td>
                    <td>{item.sewadarName}</td>
                    <td>{item.sewaDate}</td>
                    <td>{item.sewaInTime}</td>
                    <td>{item.sewaOutTime}</td>
                    <td>{item.sewaMarkedBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default DailyAttendanceReport;
