import { useEffect, useState } from "react";
import ZoneCard from "./ZoneCard";
import axios from "axios";
import { useParams } from "react-router-dom";

function ZoneWiseSummary(props) {
  const [zoneData, setZoneData] = useState([]);
  // get reload param from url

  useEffect(() => {
    var city = localStorage.getItem("reportCity");
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/summary/zonesummary",
        {
          city: city || "Indore",
        }
      )
      .then((response) => {
        setZoneData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">Zone Wise Summary</div>
        {zoneData &&
          zoneData.map((zone, index) => {
            return <ZoneCard key={index} zone={zone} />;
          })}
      </div>
    </>
  );
}
export default ZoneWiseSummary;
