import { useState, useEffect } from "react";
import axios from "axios";

function SewadarCard(props) {
  const [sewadarDetails, setSewadarDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.sewadar !== sewadarDetails) {
      setSewadarDetails(props.sewadar);
      console.log(props.sewadar);
    }
  }, [props.sewadar]);

  //   {
  //     "reportingId": 8,
  //     "sewadarBadge": "BL02464",
  //     "sewadarName": "Umabai R. Sutar",
  //     "group": 52,
  //     "reportingDateTime": "26-01-2023 09:26",
  //     "sewadarPhone": "95847 24291 C/O",
  //     "reportingCurrentPhone": "7489736816",
  //     "sewadarImage": "https://trafficdept.in/sp1/sewadarimages_new/BL02464.PNG"
  //   },

  function markReported() {
    setLoading(true);
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/reporting/markzonereporting",
        {
          reportingId: sewadarDetails.reportingId,
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "SUCCESS") {
          //delete the parent div
          document
            .getElementById("sewadarCard" + sewadarDetails.reportingId)
            .remove();
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <>
      {sewadarDetails && (
        <div
          className="col-span-12 lg:col-span-6 p-2 rounded shadow-md bg-gray-200"
          id={"sewadarCard" + sewadarDetails.reportingId}
        >
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 md:col-span-3">
              <img
                src={sewadarDetails.sewadarImage}
                alt="Sewadar"
                className="w-full h-100 object-cover"
              />
            </div>
            <div className="col-span-12 md:col-span-9">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12 md:col-span-6">
                  <div className="uppercase text-gray-700">Name</div>
                  <div className="text-xl">{sewadarDetails.sewadarName}</div>
                  <div className="uppercase text-gray-700">Badge Number</div>
                  <div className="text-xl">{sewadarDetails.sewadarBadge}</div>
                  <div className="uppercase text-gray-700">Phone</div>
                  <div className="text-xl">{sewadarDetails.sewadarPhone}</div>
                  <div className="uppercase text-gray-700">Reporting Time</div>
                  <div className="text-xl">
                    {sewadarDetails.reportingDateTime}
                  </div>
                  <div className="uppercase text-gray-700">Reporting Phone</div>
                  <div className="text-xl">
                    {sewadarDetails.reportingCurrentPhone}
                  </div>
                  <div className="uppercase text-gray-700">Group</div>
                  <div className="text-xl">{sewadarDetails?.group?.name}</div>
                </div>
              </div>
            </div>
            <div className="col-span-12">
              {loading && (
                <div className="text-right items-end">
                  <img
                    src="/images/loading.gif"
                    alt="Loading..."
                    className="h-10 w-10 text-right"
                  />
                </div>
              )}
              {!loading && (
                <div className="text-right">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      markReported();
                    }}
                  >
                    Mark Reported
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SewadarCard;
