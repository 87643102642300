import axios from "axios";
import React, { useEffect, useState } from "react";
import SewadarCard from "./SewadarCard";

function ZoneWiseReporting(props) {
  const [loading, setLoading] = useState(false);
  const [sewadarList, setSewadarList] = useState([]);

  useEffect(() => {
    var city = localStorage.getItem("reportCity") || "Indore";
    var zone = JSON.parse(localStorage.getItem("userdata")).zone;
    setLoading(true);
    axios
      .post(
        "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/reporting/getreportedsewadar",
        {
          city: city,
          zoneId: zone,
        }
      )
      .then((response) => {
        setSewadarList(response.data.sewadars);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full">
      <div className="text-3xl uppercase col-span-12">Zone Reporting</div>
      {loading && <div>Loading...</div>}
      {!loading && sewadarList.length === 0 && <div>No Sewadars</div>}
      {!loading && sewadarList.length > 0 && (
        <div className="grid grid-cols-12 gap-2 w-full mt-10">
          {sewadarList.map((sewadar) => (
            <SewadarCard sewadar={sewadar} />
          ))}
        </div>
      )}
    </div>
  );
}
export default ZoneWiseReporting;
