import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";

function ReportSewadar() {
  const [sewadar, setSewadar] = useState("");
  const [loading, setLoading] = useState(false);

  const [sewadarAttendance, setSewadarAttendance] = useState({});
  const history = useHistory();
  const [sewadarDetails, setSewadarDetails] = useState({});
  const [allowed, setAllowed] = useState(false);
  const [items, setItems] = useState([]);
  const search = useRef(null);
  const [jacketNumber, setJacketNumber] = useState(-1);
  const [attendanceKey, setAttendanceKey] = useState(0);
  const [isTraining, setIsTraining] = useState(false);

  const roleNames = {
    CR: "Coordinator",
    ZI: "Zone Incharge",
    GI: "Group Incharge",
    NS: "Sewadar",
  };

  function markAttendance() {
    if (sewadar) {
      if (sewadarDetails.gender === "M") {
        if (jacketNumber === -1 || jacketNumber === "") {
          alert("Please select jacket number");
          return;
        }
      }
      setLoading(true);
      axios
        .post(
          "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/weekly/sewadar/markattendance",
          {
            sewadarBadge: sewadar,
            userId: JSON.parse(localStorage.getItem("userdata")).username,
            jacketNo: jacketNumber,
          }
        )
        .then(function (response) {
          if (response.data.status === "Success") {
            setSewadarAttendance(JSON.parse(response.data.body));
            setAttendanceKey(attendanceKey + 1);
            setJacketNumber(-1);
          } else if (response.data.status === "Failure") {
            alert(JSON.parse(response.data.body).message);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoading(false);
        });
      setLoading(false);
    }
  }
  useEffect(() => {
    if (localStorage.getItem("userdata")) {
      var userdata = JSON.parse(localStorage.getItem("userdata"));
      if (["ADMIN", "OFFICE"].includes(userdata.role)) {
        setAllowed(true);
        axios
          .post(
            "https://a9s48sduha.execute-api.ap-northeast-1.amazonaws.com/v1/weekly/sewadar/rosterlist"
          )
          .then(function (response) {
            console.log(response.data.body);
            if (response.data.status === "success") {
              setItems(JSON.parse(response.data.body).sewadarList);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setAllowed(false);
      }
    } else {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setJacketNumber(-1);
  }, [sewadar]);

  // useEffect(() => {
  //   if (
  //     ["ADMIN", "OFFICE"].includes(
  //       JSON.parse(localStorage.getItem("userdata")).role
  //     )
  //   ) {
  //     setAllowed(true);
  //     document.getElementById("sewadarBadge").focus();
  //   } else {
  //     setAllowed(false);
  //   }
  // }, []);
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    console.log(item);
    setSewadarDetails(item);
    setSewadar(item.id);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          Badge: {item.id}
        </span>
        <span style={{ display: "block", textAlign: "left" }}>
          Name: {item.name}
          {item.isTraining && (
            <span style={{ color: "#FF0000" }}> (Training)</span>
          )}
        </span>
      </>
    );
  };

  useEffect(() => {
    if (sewadarDetails.isTraining) {
      setIsTraining(true);
    } else {
      setIsTraining(false);
    }
  }, [sewadarDetails]);

  return (
    <>
      <div className="grid grid-cols-12 gap-5">
        <div className="text-3xl uppercase col-span-12">Mark Attendance</div>
        <div className="col-span-12 md:col-span-12">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <ReactSearchAutocomplete
              key={attendanceKey}
              ref={search}
              items={items}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
              placeholder="Search Sewadar"
              fuseOptions={{
                keys: ["name"],
                threshold: 0.2,
              }}
            />
          </form>
        </div>
        {Object.keys(sewadarDetails).length > 0 &&
          sewadarDetails.gender === "M" && (
            <div className="col-span-12">
              <form>
                <div className="grid grid-cols-12 gap-5 items-center">
                  <div className="col-span-12 md:col-span-2">Jacket Number</div>
                  <div className="col-span-12 md:col-span-10">
                    <input
                      type="number"
                      name="jacketNumber"
                      id="jacketNumber"
                      className="w-full border border-gray-300 rounded-md py-2 px-4"
                      placeholder="Jacket Number"
                      value={jacketNumber === -1 ? "" : jacketNumber}
                      onChange={(e) => setJacketNumber(e.target.value)}
                    />
                    {isTraining && (
                      <div className=" text-red-600 text-xl font-semibold italic">
                        Training Sewadar
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        <div className="col-span-12 md:col-span-12">
          {!loading ? (
            <input
              type="button"
              value="Mark Attendance"
              onClick={() => markAttendance()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 md:h-12 rounded-3xl cursor-pointer w-full shadow-xl"
            />
          ) : (
            <img
              src="/images/loading.gif"
              alt="loading"
              className="w-10 h-10 mx-auto"
            />
          )}
        </div>

        {Object.keys(sewadarAttendance).length > 0 && (
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 md:col-span-2">Sewadar Name</div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.sewadarName}
              </div>
              <div className="col-span-12 md:col-span-2">
                Weekly Point Badge
              </div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.sewadarPointBadge}
              </div>
              <div className="col-span-12 md:col-span-2">Sewadar Badge</div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.sewadarBadge}
              </div>
              <div className="col-span-12 md:col-span-2">Sewadar Point</div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.pointName}
              </div>
              <div className="col-span-12 md:col-span-2">Point Zone</div>
              <div className="col-span-12 md:col-span-10 text-2xl font-bold text-green-600">
                {sewadarAttendance.pointZone}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default ReportSewadar;
