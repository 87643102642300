import './style.css';
function ErrorMessage (props) {

    return (
        <>
        <div className='w-full text-center inactive my-10' id="ErrorMessage">
            NetWork Error
        </div>
        </>
    )

}

export default ErrorMessage